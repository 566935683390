<template>
  <div :class="isDarkTheme ? 'dark-theme' : ''" class="container-scroller">
    <NavBar
      @toggleSidebarMobile="onToggleSideBarCallback"
      @toggleTheme="onToggleThemeCallback"
    ></NavBar>
    <div class="container-fluid page-body-wrapper">
      <SideBar :toggleSidebarMobile="toggleSidebarMobile"></SideBar>
      <div class="main-panel">
        <div class="content-wrapper">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/shared/common/nav-bar.vue";
import SideBar from "@/components/shared/common/side-bar.vue";
export default {
  name: "DefaultLayout",
  data() {
    return {
      isDarkTheme: localStorage.getItem("isDarkTheme") === "true" ?? false,
      toggleSidebarMobile: false,
    };
  },
  methods: {
    onToggleThemeCallback(isDarkTheme) {
      this.isDarkTheme = isDarkTheme;
    },
    onToggleSideBarCallback() {
      this.toggleSidebarMobile = !this.toggleSidebarMobile;
    },
  },
  components: {
    NavBar,
    SideBar,
  },
};
</script>
