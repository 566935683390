<template>
  <GenericModal ref="modal" :modalId="modalId">
    <MealForm
      @mealUpdated="addMealCallback"
      @mealDeleted="deleteMealCallback"
      @mealAdded="addMealCallback"
      @mealDiscarded="$refs.modal.close()"
      :selected-meal="selectedMeal"
      :selected-week-day="selectedWeekDay"
      :form-mode="formMode"
    ></MealForm>
  </GenericModal>
</template>
<script>
import MealForm from "@/components/meals/meal-form.vue";
import GenericModal from "../shared/common/generic-modal.vue";

export default {
  name: "MealModal",
  components: { MealForm, GenericModal },
  props: ["selectedMeal", "selectedWeekDay", "formMode", "modalId"],
  methods: {
    addMealCallback(meal) {
      this.$emit("mealAdded", meal);
      this.$refs.modal.close();
    },
    deleteMealCallback(meal) {
      this.$emit("mealDeleted", meal);
      this.$refs.modal.close();
    },
  },
};
</script>
