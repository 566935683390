<template>
  <form @submit="onSubmit($event)">
    <AlertMessage ref="alertMessage"></AlertMessage>
    <div class="row">
      <div class="col-xl-6 col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Platillos</h3>
            <br />
            <MealAutocomplete
              @onMealAdded="addMealCallback"
              v-show="formMode === 'dietEdit'"
            ></MealAutocomplete>
            <div class="row mt-2">
              <div class="col-6">
                <select required v-model="meal.type" class="form-control">
                  <option value="">Tipo</option>
                  <option value="Desayuno">Desayuno</option>
                  <option value="Comida">Comida</option>
                  <option value="Cena">Cena</option>
                  <option value="Colacion">Colacion</option>
                </select>
              </div>
              <div class="col-6">
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="meal.name"
                  placeholder="Nombre"
                />
              </div>
            </div>
            <h4 class="card-title mt-5">Ingredientes</h4>
            <p>Lista de ingredientes del platillo</p>
            <IngredientAutocomplete
              @onIngredientSelected="onIngredientSelectedCallback"
            ></IngredientAutocomplete>
            <div
              class="food-item"
              v-for="(food, index) in meal.food"
              :key="food.id"
            >
              <div class="food-ingridient">
                <a @click="removeFood(index)" class="mr-4 btn">
                  <i class="fa-solid fa-remove text-danger"></i> </a
                ><input
                  type="number"
                  step="any"
                  class="form-control form-control-sm food-quantity"
                  v-model="food.quantity"
                  placeholder="Cantidad"
                  @change="calculateFood(food)"
                />
                &nbsp;({{ food.unit }})&nbsp; - <strong>{{ food.name }}</strong>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <a @click="discard()" class="btn btn-secondary mr-2"> Descartar </a>
            <a
              v-show="
                formMode === 'addEdit' && meal.id !== undefined && meal.id > 0
              "
              @click="deleteMeal()"
              class="btn btn-secondary mr-2"
            >
              Eliminar
            </a>
            <button
              v-show="formMode === 'addEdit'"
              type="submit"
              class="btn btn-primary mr-2"
            >
              <span
                v-show="
                  formMode === 'addEdit' && meal.id !== undefined && meal.id > 0
                "
              >
                Actualizar
              </span>
              <span v-show="formMode !== 'addEdit'"> Agregar </span>
              <span
                v-show="
                  formMode === 'addEdit' &&
                  (meal.id === undefined || meal.id === 0)
                "
              >
                Guardar
              </span>
            </button>
            <button
              @click="this.meal.store = true"
              type="submit"
              class="btn btn-primary mr-2"
              v-show="
                (meal.id === undefined || meal.id === 0) &&
                formMode === 'dietEdit'
              "
            >
              Agregar y Guardar
            </button>
            <button
              type="submit"
              class="btn btn-primary mr-2"
              v-show="meal.id > 0 && formMode === 'dietEdit'"
            >
              Agregar
            </button>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Aportes Nutricionales</h4>
            <MealDetails
              :selectedMeal="meal"
              :recalculateChart="refreshDetails"
            ></MealDetails>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<style scoped>
select.form-control {
  padding: 0.875rem 1.375rem;
}

.food-ingridient {
  font-size: 11pt;
  margin: 25px 0 0 0;
  padding-bottom: 2px;
  border-bottom: 1px solid #e9ecef;
}

.food-ingridient a {
  cursor: pointer;
}
.food-quantity {
  width: 50px;
  display: inline-block;
  border: none;
  padding: 3px;
  background: #e9ecef;
  text-align: center;
  font-weight: 600;
}

.form-check {
  display: inline-block;
}
</style>
<script>
import axios from "axios";
import IngredientAutocomplete from "@/components/ingredients/ingredient-autocomplete.vue";
import MealAutocomplete from "@/components/meals/meal-autocomplete.vue";
import MealDetails from "@/components/meals/meal-details.vue";
import AlertMessage from "../shared/common/alert-message.vue";

export default {
  name: "MealForm",
  components: {
    IngredientAutocomplete,
    MealAutocomplete,
    MealDetails,
    AlertMessage,
  },
  data() {
    return {
      meal: this.getEmptyMeal(),
      refreshDetails: false,
    };
  },
  props: ["selectedMeal", "selectedWeekDay", "formMode"],
  watch: {
    selectedMeal: function (newVal) {
      this.meal = newVal;
      if (this.meal.id === undefined || this.meal.isEmpty) {
        this.meal = this.getEmptyMeal();
      }
      if (this.meal.food !== undefined) {
        this.calculateMeal();
      }
    },
  },
  methods: {
    getEmptyMeal() {
      return {
        type: "",
        name: "",
        food: [],
        kcal: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
        store: false,
        actionMode: "add",
      };
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.formMode === "addEdit") {
        if (this.meal.id === undefined || this.meal.id === 0) {
          this.saveMeal();
        } else {
          this.updateMeal();
        }
      } else {
        if (this.meal.store) {
          this.saveMeal();
        } else {
          this.$emit("mealUpdated", this.meal);
          this.clearMeal();
        }
      }
    },
    saveMeal() {
      axios.post("/meals/", this.meal).then(
        (response) => {
          console.log(response);
          if (response.data) {
            this.meal = response.data;
            this.$emit("mealAdded", this.meal);
            this.clearMeal();
            this.$refs.alertMessage.showAlert(
              "El platillo fue guardado correctamente",
              true
            );
          } else {
            this.showErrorMessage();
          }
        },
        () => {
          this.showErrorMessage();
        }
      );
    },
    updateMeal() {
      axios.put("/meals/" + this.meal.id, this.meal).then(
        (response) => {
          if (response.data) {
            this.$emit("mealUpdated", this.meal);
            this.clearMeal();
            this.$refs.alertMessage.showAlert(
              "El platillo fue actualizado correctamente",
              true
            );
          } else {
            this.showErrorMessage();
          }
        },
        () => {
          this.showErrorMessage();
        }
      );
    },
    deleteMeal() {
      if (this.meal.id !== undefined && this.meal.id > 0) {
        axios.delete("/meals/" + this.meal.id).then(
          (response) => {
            if (response.data) {
              this.$emit("mealDeleted", response.data);
              this.$refs.alertMessage.showAlert(
                "El platillo fue eliminado correctamente",
                true
              );
            } else {
              this.showErrorMessage();
            }
          },
          () => {
            this.showErrorMessage();
          }
        );
      }

      this.clearMeal();
    },
    discard() {
      this.clearMeal();
      this.$emit("mealDiscarded");
    },
    clearMeal() {
      this.meal = this.getEmptyMeal();
    },
    onIngredientSelectedCallback(food) {
      this.meal.food.push(food);
      this.calculateFood(food);
    },
    addMealCallback(meal) {
      this.meal = meal;
      this.calculateMeal();
    },
    removeFood(index) {
      this.meal.food.splice(index, 1);
      this.calculateMeal();
    },
    calculateFood(food) {
      var quantityValue = parseFloat(food.quantity);
      food.kcal = parseInt(
        quantityValue * (food.baseFood.kcal / food.baseFood.basePortion)
      );
      food.carbs = parseInt(
        quantityValue * (food.baseFood.carbs / food.baseFood.basePortion)
      );
      food.protein = parseInt(
        quantityValue * (food.baseFood.protein / food.baseFood.basePortion)
      );
      food.fat = parseInt(
        quantityValue * (food.baseFood.fat / food.baseFood.basePortion)
      );

      this.calculateMeal();
    },
    calculateMeal() {
      this.meal.kcal = 0;
      this.meal.carbs = 0;
      this.meal.fat = 0;
      this.meal.protein = 0;

      for (let index = 0; index < this.meal.food.length; index++) {
        let element = this.meal.food[index];
        this.meal.kcal += element.kcal;
        this.meal.carbs += element.carbs;
        this.meal.fat += element.fat;
        this.meal.protein += element.protein;
      }

      this.refreshDetails = !this.refreshDetails;
    },
    showErrorMessage() {
      this.$refs.alertMessage.showAlert(
        "Ocurrio un error, porfavor intenta de nuevo",
        false
      );
    },
  },
};
</script>
