<template>
  <form @submit="onSubmit($event)">
    <AlertMessage ref="alertMessage"></AlertMessage>
    <div class="row">
      <div class="col-xl-6 col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Ingrediente</h3>
            <br />
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="contextItem.name"
                placeholder="Nombre"
                required
              />
            </div>
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label for="kcal">Kcal</label>
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    id="kcal"
                    v-model="contextItem.kcal"
                    placeholder="Kcal"
                    required
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="carbs">Carbs (gr.)</label>
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    id="carbs"
                    v-model="contextItem.carbs"
                    placeholder="Carbs"
                    required
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="fat">Grasas (gr.)</label>
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    id="fat"
                    v-model="contextItem.fat"
                    placeholder="Grasas"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label for="protein">Proteinas (gr.)</label>
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    id="protein"
                    v-model="contextItem.protein"
                    placeholder="Proteinas"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="protein">Unidad</label>
                  <input
                    type="text"
                    class="form-control"
                    id="unit"
                    v-model="contextItem.unit"
                    placeholder="Unidad"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="quantity">Porcion</label>
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    id="quantity"
                    v-model="contextItem.quantity"
                    placeholder="Cantidad"
                  />
                </div>
              </div>
            </div>
            <div class="float-right">
              <a
                v-if="contextItem.canEdit === undefined || contextItem.canEdit"
                @click="clear()"
                class="btn btn-secondary mr-2"
                >Descartar</a
              >
              <button
                v-if="contextItem.canEdit === undefined || contextItem.canEdit"
                type="submit"
                class="btn btn-primary"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Aportes Nutricionales</h4>
            <MealDetails
              :selectedMeal="contextItem"
              :recalculateChart="refreshDetails"
            ></MealDetails>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import axios from "axios";
import MealDetails from "@/components/meals/meal-details.vue";
import AlertMessage from "../shared/common/alert-message.vue";

export default {
  name: "IngredientForm",
  components: { MealDetails, AlertMessage },
  props: ["selectedIngredient"],
  watch: {
    selectedIngredient: function (newVal) {
      if (newVal !== null && newVal.name !== "") {
        this.contextItem = newVal;
        this.refreshDetails = !this.refreshDetails;
      }
    },
  },
  data() {
    return {
      contextItem: this.getContextItem(),
      refreshDetails: false,
    };
  },
  methods: {
    getContextItem() {
      return {
        name: "",
        kcal: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
        unit: "",
        quantity: 0,
        canEdit: true,
      };
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.contextItem.id === undefined) {
        axios.post("/ingredients", this.contextItem).then(
          (response) => {
            if (response.data) {
              this.$refs.alertMessage.showAlert(
                "Ingrediente creado correctamente",
                true
              );

              this.$emit("ingredientCreated", response.data);
            } else {
              this.showErrorMessage();
            }
          },
          () => {
            this.showErrorMessage();
          }
        );
      } else {
        axios.put("/ingredients/" + this.contextItem.id, this.contextItem).then(
          (response) => {
            this.$emit("ingredientUpdated", response.data);
          },
          () => {
            this.showErrorMessage();
          }
        );
      }
    },
    clear() {
      this.contextItem = this.getContextItem();
    },
    showErrorMessage() {
      this.$refs.alertMessage.showAlert(
        "Ocurrio un error, porfavor intenta de nuevo",
        false
      );
    },
  },
};
</script>
