import { createRouter, createWebHistory } from "vue-router";
import DietsDesginer from "../views/Diets/DietsDesginerView.vue";
import DietsListView from "../views/Diets/DietsListView.vue";
import MealsListView from "../views/Meals/MealsListView.vue";
import IngredientsListView from "../views/Ingredients/IngredientsListView.vue";
import PatientsListView from "../views/Patients/PatientsListView.vue";
import PatientDashboardView from "@/views/Patients/PatientDashboardView.vue";
import LoginLayout from "@/views/layouts/login-layout.vue";
//import Dashboard from "@/views/General/dashboard-view.vue";
import NotFound from "@/views/Default/not-found.vue";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "home",
    component: DietsDesginer,
    meta: { requiresAuth: true },
  },
  {
    path: "/diets/new",
    name: "diet-new",
    component: DietsDesginer,
    meta: { requiresAuth: true },
  },
  {
    path: "/diets/list",
    name: "diets",
    component: DietsListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/diets/edit/:id",
    name: "diet-edit",
    component: DietsDesginer,
    meta: { requiresAuth: true },
  },
  {
    path: "/patients/list",
    name: "patients",
    component: PatientsListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/patients/dashboard/:id",
    name: "patient-details",
    component: PatientDashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/meals/list",
    name: "meals",
    component: MealsListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/ingredients/list",
    name: "ingredients",
    component: IngredientsListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginLayout,
  },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    axios.get("/authentication/validatetoken");
  }

  next();
});

export default router;
