<template>
  <div class="card">
    <div class="card-body">
      <form @submit="onsubmit($event)">
        <div class="row">
          <div class="col-12">
            <h4 class="card-title">
              <i class="fa-solid fa-ruler-vertical"></i> Medidas Primarias
            </h4>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="age">Edad</label>
              <input
                disabled
                type="text"
                class="form-control"
                id="age"
                v-model="patientControl.age"
                placeholder="Edad"
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="height">Altura</label>
              <input class="form-control" v-model="patientControl.height" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="weight">Peso</label>
              <input class="form-control" v-model="patientControl.weight" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="card-title">
              <i class="fa-solid fa-weight"></i> Composicion Corporal
            </h4>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="fatPercentage">% Grasa</label>
              <input
                v-model="patientControl.fatPercentage"
                class="form-control"
                type="text"
                required
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="kgFat">Kg. Grasa</label>
              <input
                v-model="patientControl.kgFat"
                class="form-control"
                type="text"
                required
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="musclePercentage">% Musculo</label>
              <input
                v-model="patientControl.musclePercentage"
                class="form-control"
                type="text"
                required
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="kgMuscle">Kg. Musculo</label>
              <input
                v-model="patientControl.kgMuscle"
                class="form-control"
                type="text"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="card-title">
              <i class="fa-solid fa-scale-unbalanced-flip"></i> Medidas
              Secundarias
            </h4>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="h5" for="viceralFat">Grasa Viceral </label>
              <input
                v-model="patientControl.viceralFat"
                class="form-control"
                type="text"
                required
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="bloodPressure">Presion Arterial</label>
              <input
                type="text"
                class="form-control"
                id="bloodPressure"
                v-model="patientControl.bloodPressure"
                placeholder="Presion Arterial"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="card-title">
              <i class="fa-solid fa-person"></i> Circunferencias Corporales
            </h4>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="abdominalCircumference"
                >Circunferencia Abdominal</label
              >

              <input
                v-model="patientControl.abdominalCircumference"
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="bicepCircunference">Circunferencia Bicep</label>
              <input
                v-model="patientControl.bicepCircunference"
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="chestCircunference">Circunferencia Pecho</label>
              <input
                v-model="patientControl.chestCircunference"
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="legCircunference">Circunferencia Pierna</label>
              <input
                v-model="patientControl.legCircunference"
                class="form-control"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <a @click="clearData()" class="btn btn-secondary mr-2">
              Descartar
            </a>
            <button type="submit" class="btn btn-primary">
              Guardar <i class="fa-solid fa-floppy-disk"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PatientTrackerForm",
  props: ["selectedPatient"],
  watch: {
    selectedPatient: {
      handler: function (val) {
        this.patientControl =
          val.patientControlHistory[0] ?? this.getEmptyData();
        this.patientControl.patientId = val.patient.id;
        console.log(val.patient.birthDay);
        this.patientControl.age = this.calulateAge(val.patient.birthDay);
      },
      deep: true,
    },
  },
  data() {
    return {
      patientControl: this.getEmptyData(),
    };
  },
  methods: {
    calulateAge(birthDay) {
      const today = new Date();
      const birthDate = new Date(birthDay);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      console.log(monthDifference);
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      console.log(age);

      return age;
    },
    getEmptyData() {
      return {
        patientId: 0,
        age: 0,
        height: 0,
        weight: 0,
        fatPercentage: 0,
        kgFat: 0,
        musclePercentage: 0,
        kgMuscle: 0,
        metabolicAge: 0,
        viceralFat: 0,
        bloodPressure: "",
        abdominalCircumference: 0,
        bicepCircunference: 0,
        chestCircunference: 0,
        legCircunference: 0,
      };
    },
    onsubmit(e) {
      e.preventDefault();

      axios.post("/patients/track", this.patientControl).then((response) => {
        this.$emit("PatientControlAdded", response.data);
        this.clearData();
      });
    },
    clearData() {
      this.patientControl = this.getEmptyData();
    },
  },
};
</script>
