<template>
  <div class="auth-form-transparent text-left p-3">
    <div class="brand-logo">
      <!--<img src="../../images/logo-dark.svg" alt="logo" />-->
    </div>
    <h4>
      <span class="badge badge-primary"
        ><i class="fa-solid fa-wheat-awn"></i></span
      >&nbsp;Nutrition Suite <br />
    </h4>
    <h6 class="font-weight-light">Bienvenido!</h6>
    <form class="pt-3" @submit="login($event)">
      <AlertMessage ref="alertMessage"></AlertMessage>
      <div class="form-group">
        <label for="exampleInputEmail">Usuario</label>
        <div class="input-group">
          <div class="input-group-prepend bg-transparent">
            <span class="input-group-text bg-transparent border-right-0">
              <i class="mdi mdi-account-outline text-primary"></i>
            </span>
          </div>
          <input
            v-model="user.username"
            type="text"
            class="form-control form-control-lg border-left-0"
            id="exampleInputEmail"
            placeholder="Username"
            required
          />
        </div>
      </div>
      <div class="form-group">
        <label for="exampleInputPassword">Password</label>
        <div class="input-group">
          <div class="input-group-prepend bg-transparent">
            <span class="input-group-text bg-transparent border-right-0">
              <i class="mdi mdi-lock-outline text-primary"></i>
            </span>
          </div>
          <input
            v-model="user.password"
            type="password"
            class="form-control form-control-lg border-left-0"
            id="exampleInputPassword"
            placeholder="Password"
            required
          />
        </div>
      </div>
      <div class="my-3">
        <button
          :disabled="isFormProcessing"
          type="submit"
          class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
        >
          <span v-if="!isFormProcessing"> LOGIN </span>
          <i v-else class="fa-solid fa-circle-notch fa-spin"></i>
        </button>
      </div>
    </form>
  </div>
</template>
<style scoped>
form {
  position: relative;
}
</style>
<script>
import axios from "axios";
import AlertMessage from "@/components/shared/common/alert-message.vue";
export default {
  name: "LoginForm",
  components: {
    AlertMessage,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      isFormProcessing: false,
    };
  },
  methods: {
    login(event) {
      event.preventDefault();
      this.isFormProcessing = true;
      axios.post("/authentication/authtoken", this.user).then(
        (response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$router.push("/");
        },
        () => {
          this.isFormProcessing = false;
          this.$refs.alertMessage.showAlert(
            "El Usuario o contraseña son incorrectos",
            false
          );
        }
      );
    },
  },
};
</script>
