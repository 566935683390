<template>
  <DefaultLayout>
    <div class="row">
      <div class="col-sm-12 mb-4 mb-xl-0">
        <h4 class="font-weight-bold text-dark">Ingredientes</h4>
        <p class="font-weight-normal mb-2 text-muted">{{ today }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 grid-margin">
        <SearchGridComponent
          :modalId="modalId"
          endpoint="ingredients"
          :column-names="[
            'Nombre',
            'Tipo',
            'Kcal',
            'Carbs',
            'Proteinas',
            'Grasas',
          ]"
          :properties="['name', 'type', 'kcal', 'carbs', 'protein', 'fat']"
          :columnClasses="[
            '',
            'badge badge-secondary',
            'badge badge-warning',
            'badge badge-info',
            'badge badge-success',
            'badge badge-danger',
          ]"
          :pageSize="100"
          @itemSelected="itemSelectedCallback"
          :trigger-unselect="triggerUnselectOnGrid"
          :trigger-refresh="triggerRefreshOnGrid"
        ></SearchGridComponent>
      </div>
      <IngredientModal
        @ingredientCreated="createdCallback"
        @ingredientUpdated="updatedCallback"
        :modalId="modalId"
        :selected-ingredient="selectedItem"
      ></IngredientModal>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/views/layouts/default-layout.vue";
import SearchGridComponent from "@/components/shared/grids/search-grid.vue";
import IngredientModal from "@/components/ingredients/ingredient-modal.vue";

export default {
  name: "IngredientsListView",
  components: {
    DefaultLayout,
    SearchGridComponent,
    IngredientModal,
  },
  data() {
    return {
      today: new Date().toDateString(),
      selectedItem: {},
      modalId: "ingredient-modal-component",
      triggerUnselectOnGrid: false,
      triggerRefreshOnGrid: false,
    };
  },
  methods: {
    itemSelectedCallback(item) {
      this.selectedItem = item;
    },
    createdCallback() {
      this.selectedItem = {};
      this.triggerRefreshOnGrid = !this.triggerRefreshOnGrid;
    },
    updatedCallback() {
      this.selectedItem = {};
      this.triggerUnselectOnGrid = !this.triggerUnselectOnGrid;
    },
  },
};
</script>
