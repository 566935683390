<template>
  <div>
    <ul>
      <li>
        <span class="h6 ml-3 mb-2">
          <strong>
            <i class="fa-solid fa-fire"></i> Kcal:
            <span class="h6 text-dark badge badge-danger">
              {{ selectedMeal.kcal }}
            </span>
          </strong>
        </span>
      </li>
      <li>
        <span class="h6 ml-3 mb-2">
          <strong>
            <i class="fa-solid fa-egg"></i> Proteina (gr.):
            <span class="h6 badge badge-success">
              {{ selectedMeal.protein }}
            </span>
          </strong>
        </span>
      </li>
      <li>
        <span class="h6 ml-3 mb-2">
          <strong>
            <i class="fa-solid fa-pizza-slice"></i> Carbs (gr.):
            <span class="h6 badge badge-info">
              {{ selectedMeal.carbs }}
            </span>
          </strong>
        </span>
      </li>
      <li>
        <span class="h6 ml-3 mb-2">
          <strong>
            <i class="fa-solid fa-bacon"></i> Grasa (gr.):
            <span class="h6 badge badge-warning">
              {{ selectedMeal.fat }}
            </span>
          </strong>
        </span>
      </li>
    </ul>
    <div class="chart-wrapper">
      <PieChart
        v-if="chartData.datasets.length > 0"
        :chartData="chartData"
        :options="options"
      ></PieChart>
    </div>
  </div>
</template>
<style scoped>
.chart-wrapper {
  width: 200px;
  margin: 0 auto;
}
.badge {
  font-size: 10pt;
}
</style>
<script>
import { PieChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

let defaultColors = [
  "rgba(81, 200, 28, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(252, 180, 29, 0.5)",
];

let defaultBorderColors = [
  "rgba(81,200,28,1)",
  "rgba(54, 162, 235, 1)",
  "rgba(252, 180, 29, 1)",
];

let defaultLabels = ["Proteina", "Carbs", "Grasa"];

Chart.register(...registerables);

export default {
  name: "MealDetails",
  components: { PieChart },
  props: ["selectedMeal", "recalculateChart"],
  watch: {
    recalculateChart: function () {
      this.chartData.datasets[0].data = [
        this.selectedMeal.protein,
        this.selectedMeal.carbs,
        this.selectedMeal.fat,
      ];
    },
  },
  data() {
    return {
      chartData: {
        labels: defaultLabels,
        datasets: [
          {
            data: [
              this.selectedMeal.protein,
              this.selectedMeal.carbs,
              this.selectedMeal.fat,
            ],
            backgroundColor: defaultColors,
            borderColor: defaultBorderColors,
          },
        ],
      },
      options: {
        responsive: true,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
    };
  },
};
</script>
