<template>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th v-if="disableSelection !== true"></th>
          <th v-for="column in columnNames" :key="column">{{ column }}</th>
        </tr>
      </thead>
      <tbody v-if="dataSource !== undefined && dataSource.length > 0">
        <tr
          @click="selectItem(item)"
          @dblclick="doubleClickItem(item)"
          v-for="item in dataSource"
          :key="item.id"
        >
          <td v-if="disableSelection !== true" class="text-center">
            <div class="form-check form-check-primary">
              <label class="form-check-label">
                <input
                  v-model="item.isSelected"
                  v-bind:value="item.id"
                  type="checkbox"
                  class="form-check-input" />
                <i class="input-helper"></i
              ></label>
            </div>
          </td>
          <td v-for="(property, index) in propertiesDisplayed" :key="property">
            <span :class="columnClasses[index]">{{
              formatData(
                item[property],
                formats !== undefined && formats.length > 0
                  ? formats[index]
                  : undefined
              )
            }}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center" :colspan="propertiesDisplayed.length + 1">
            No hay datos para mostrar
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.table-responsive {
  overflow-y: auto;
  max-height: 500px;
}

.table thead {
  position: sticky;
  top: -1px;
  background-color: #fff;
  z-index: 1;
}
.table tbody tr {
  cursor: pointer;
}

.table tbody tr:hover {
  background: #ebebeb;
}
.table tbody tr td {
  color: #000;
}

.table tbody tr td .badge {
  font-size: 100%;
  color: #000;
}

.table tbody tr td .form-check {
  margin: 0;
}

.table thead tr th {
  color: #000;
  font-weight: 600;
  border-left: 1px solid #e9ecef;
}
.table thead tr th:first-child {
  border-left: none;
}
</style>
<script>
export default {
  name: "DynamicGrid",
  props: [
    "disableSelection",
    "dataSource",
    "columnNames",
    "propertiesDisplayed",
    "editRoute",
    "detailsRoute",
    "columnClasses",
    "formats",
    "selectedItem",
    "triggerUnselect",
  ],
  watch: {
    triggerUnselect: function () {
      this.selectItem({});
    },
  },
  methods: {
    selectItem(item) {
      this.dataSource.forEach((element) => {
        if (element.id !== item.id) {
          element.isSelected = false;
        }
      });

      item.isSelected = !item.isSelected;

      if (item.isSelected) {
        this.$emit("itemSelected", item);
      } else {
        this.$emit("itemSelected", {});
      }
    },
    doubleClickItem(item) {
      this.dataSource.forEach((element) => {
        if (element.id !== item.id) {
          element.isSelected = false;
        }
      });
      item.isSelected = true;
      this.$emit("itemDoubleClicked", item);
    },
    formatData(value, format) {
      if (value === undefined || value === null) {
        return "";
      }

      if (format === undefined || format === null) {
        return value;
      }

      if (format === "date") {
        const date = new Date(value);
        // Then specify how you want your dates to be formatted
        value = new Intl.DateTimeFormat("default", {
          dateStyle: "short",
        }).format(date);
      }

      return value;
    },
  },
};
</script>
