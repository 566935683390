<template>
  <GenericModal :customClass="'modal-dietdesigner-preview'" :modal-id="modalId">
    <DietDesigner
      :designer-mode="'Preview'"
      :selected-diet="selectedDiet"
    ></DietDesigner>
  </GenericModal>
</template>
<style>
.modal-dietdesigner-preview {
  max-width: 90%;
}
</style>
<script>
import DietDesigner from "./diet-designer.vue";
import GenericModal from "../shared/common/generic-modal.vue";

export default {
  name: "DietModal",
  components: { DietDesigner, GenericModal },
  props: ["selectedDiet", "modalId"],
};
</script>
