<template>
  <div v-show="totalPages > 1" class="btn-group" role="group">
    <button @click="clickPrevious" class="btn btn-primary text-white">
      <i class="mdi mdi-chevron-double-left"></i>
    </button>
    <button
      v-for="page in totalPages"
      :key="page"
      type="button"
      class="btn btn-primary text-white"
      :class="{ active: currentIndex == page }"
      @click="clickPage(page)"
    >
      {{ page }}
    </button>
    <button @click="clickNext" class="btn btn-primary text-white">
      <i class="mdi mdi-chevron-double-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "GridPager",
  props: ["pagerResult"],
  data() {
    return { currentIndex: 1 };
  },
  methods: {
    clickPage(index) {
      this.$emit("clickPage", index - 1);
      this.currentIndex = index;
    },
    clickNext() {
      if (this.currentIndex < this.totalPages) {
        this.currentIndex++;
        this.$emit("clickPage", this.currentIndex - 1);
      }
    },
    clickPrevious() {
      if (this.currentIndex > 1) {
        this.currentIndex--;
        this.$emit("clickPage", this.currentIndex - 1);
      }
    },
  },
  computed: {
    totalPages() {
      if (this.pagerResult !== undefined && this.pagerResult !== null) {
        return parseInt(
          (this.pagerResult.totalCount + this.pagerResult.pageSize - 1) /
            this.pagerResult.pageSize
        );
      }

      return 0;
    },
  },
};
</script>
