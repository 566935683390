<template>
  <GenericModal ref="modal" :modalId="modalId">
    <patientTrackerForm
      @patientControlAdded="patientControlAddedCallback"
      :selected-patient="selectedPatient"
    ></patientTrackerForm>
  </GenericModal>
</template>
<script>
import patientTrackerForm from "./patient-tracker-form.vue";
import GenericModal from "../shared/common/generic-modal.vue";
export default {
  name: "PatientTrackerModal",
  components: { patientTrackerForm, GenericModal },
  props: ["selectedPatient", "modalId"],
  methods: {
    patientControlAddedCallback(patient) {
      this.$emit("patientControlAdded", patient);
      this.$refs.modal.close();
    },
  },
};
</script>
