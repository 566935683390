<template>
  <div :class="classes" role="alert">
    <i :class="iconClass"></i>
    {{ message }}
    <button @click="closeAlert" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "AlertMessage",
  data() {
    return {
      classes: "alert hide",
      message: "",
      success: false,
      iconClass: "",
    };
  },
  methods: {
    showAlert(message, success) {
      this.message = message;
      this.classes = success
        ? "alert fade show alert-success animate-open"
        : "alert fade show alert-danger animate-open";
      this.success = success;
      this.iconClass = this.success
        ? "fa-solid fa-check-circle"
        : "fa-solid fa-exclamation-circle";

      setTimeout(() => {
        this.closeAlert();
      }, 4000);
    },
    closeAlert() {
      this.classes = "alert hide";
      this.message = "";
      this.success = false;
      this.iconClass = "";
    },
  },
};
</script>
