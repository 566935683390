<template>
  <DefaultLayout>
    <div class="row">
      <div class="col-12 col-xs-12 grid-margin">
        <SearchGridComponent
          :modalId="'diet-meal-modal-component'"
          endpoint="meals"
          :column-names="[
            'Nombre',
            'Tipo',
            'Kcal',
            'Carbs',
            'Proteinas',
            'Grasas',
          ]"
          :properties="['name', 'type', 'kcal', 'carbs', 'protein', 'fat']"
          :columnClasses="[
            '',
            'badge badge-primary',
            'badge badge-warning',
            'badge badge-info',
            'badge badge-success',
            'badge badge-danger',
          ]"
          @itemSelected="itemSelectedCallback"
          :trigger-unselect="triggerUnselectOnGrid"
          :trigger-refresh="triggerRefreshOnGrid"
        ></SearchGridComponent>
      </div>
      <MealModal
        @mealAdded="onAddMealCallback"
        @mealDeleted="onMealDeletedCallback"
        @mealUpdated="onEditMealCallback"
        :modalId="'diet-meal-modal-component'"
        :selected-meal="selectedItem"
        :form-mode="'addEdit'"
      ></MealModal>
    </div>
  </DefaultLayout>
</template>

<script>
import SearchGridComponent from "@/components/shared/grids/search-grid.vue";
import MealModal from "@/components/meals/meal-modal.vue";
import DefaultLayout from "@/views/layouts/default-layout.vue";

export default {
  name: "DietsListView",
  components: {
    SearchGridComponent,
    MealModal,
    DefaultLayout,
  },
  data() {
    return {
      today: new Date().toDateString(),
      selectedItem: {},
      triggerUnselectOnGrid: false,
      triggerRefreshOnGrid: false,
    };
  },
  methods: {
    itemSelectedCallback(item) {
      this.selectedItem = item;
    },
    onAddMealCallback() {
      this.selectedItem = {};
      this.triggerRefreshOnGrid = !this.triggerRefreshOnGrid;
    },
    onEditMealCallback() {
      this.selectedItem = {};
      this.triggerUnselectOnGrid = !this.triggerUnselectOnGrid;
    },
    onMealDeletedCallback() {
      this.selectedItem = {};
      this.triggerRefreshOnGrid = !this.triggerRefreshOnGrid;
    },
  },
};
</script>
