<template>
  <nav
    :class="toggleSidebarMobile ? 'active' : ''"
    class="sidebar sidebar-offcanvas"
    id="sidebar"
  >
    <div class="user-profile">
      <div class="user-image">
        <img src="/static/img/andy.jpg" />
      </div>
      <div class="user-name">{{ user.firstName }} {{ user.lastName }}</div>
      <div class="user-designation">Lic. Nutricion</div>
    </div>
    <ul class="nav">
      <li class="nav-item">
        <router-link to="/patients/list" class="nav-link">
          <i class="icon-head menu-icon"></i>
          <span class="menu-title">Pacientes</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/diets/list" class="nav-link">
          <i class="icon-layout menu-icon"></i>
          <span class="menu-title">Dietas</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/meals/list" class="nav-link">
          <i class="icon-stack menu-icon"></i>
          <span class="menu-title">Platillos</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/ingredients/list" class="nav-link">
          <i class="icon-box menu-icon"></i>
          <span class="menu-title">Ingredientes</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<style scoped>
@media print {
  .sidebar {
    display: none;
  }
}
</style>
<script>
export default {
  name: "SideBar",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user") ?? "{}"),
    };
  },
  props: ["toggleSidebarMobile"],
};
</script>
