<template>
  <div>
    <div class="input-group search-bar-component mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa-solid fa-magnifying-glass"></i>
        </span>
      </div>

      <input
        @keyup="onKeyUp"
        @keydown="keydown"
        type="text"
        class="form-control auto-complete-input"
        placeholder="Buscar Platillo..."
        aria-label="search"
        aria-describedby="search"
        v-model="searchTerm"
      />
      <span class="processing-icon" :class="showLoader ? 'show' : ''">
        <i class="fa-spin fa-solid fa-spinner"></i>
      </span>
      <div class="auto-complete-wrapper">
        <div class="list-group auto-complete-list" role="tablist">
          <a
            v-for="(result, index) in resultsList"
            :key="result.id"
            class="list-group-item list-group-item-action"
            data-toggle="list"
            role="tab"
            aria-controls="home"
            @click="onSelect(index)"
            ><span class="h6">{{ result.name }}</span
            ><br /><br />
            <span class="mr-1 badge badge-info"
              >kcal:&nbsp;{{ result.kcal }}</span
            >
            <span class="mr-1 badge badge-info"
              >carbs:&nbsp;{{ result.carbs }}</span
            >
            <span class="mr-1 badge badge-info"
              >Grasa:&nbsp;{{ result.fat }}</span
            >
            <span class="mr-1 badge badge-info"
              >Proteina:&nbsp;{{ result.protein }}</span
            ></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.processing-icon {
  display: none;
}

.processing-icon.show {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 11px;
  color: #470f5f;
  font-size: 18pt;
}

.auto-complete-wrapper {
  position: absolute;
  z-index: 9999;
  top: 47px;
  right: 0;
  left: 0;
  min-width: 450px;
  z-index: 1;
}
.auto-complete-list {
  max-height: 400px;
  overflow-y: scroll;
}

.list-group-item.list-group-item-action {
  cursor: pointer;
}
.list-group-item.list-group-item-action.active {
  background: #470f5f;
}
</style>

<script>
let typingTimer;
import axios from "axios";
export default {
  name: "Meal-Auto-complete",
  data() {
    return {
      searchTerm: "",
      resultsList: [],
      selectedResult: {},
      showLoader: false,
    };
  },
  methods: {
    onKeyUp(e) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.doSearch(e);
      }, 500);
    },
    onKeyDown() {
      clearTimeout(typingTimer);
    },
    doSearch(e) {
      if (this.searchTerm.length > 3) {
        this.showLoader = true;
        axios.get("/meals/search?term=" + e.target.value).then((response) => {
          this.resultsList = response.data.results;
          this.showLoader = false;
        });
      }
    },
    onSelect(index) {
      this.selectedResult = this.resultsList[index];
      this.$emit("onMealAdded", this.selectedResult);
      this.searchTerm = "";
      this.resultsList = [];
      this.selectedResult = {};
    },
  },
};
</script>
