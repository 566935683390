<template>
  <div class="col-12 grid-margin">
    <form @submit="onSubmit">
      <div class="card">
        <div class="card-body">
          <AlertMessage ref="alertMessage"></AlertMessage>

          <h4 class="card-title">
            <i class="icon-head menu-icon"></i> Pacientes
          </h4>
          <p class="card-description">Informacion Personal</p>
          <input type="hidden" v-model="patient.id" />
          <input type="hidden" v-model="patient.active" />
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Nombre</label>
                <div class="col-sm-9">
                  <input
                    v-model="patient.firstName"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Apellidos</label>
                <div class="col-sm-9">
                  <input
                    v-model="patient.lastName"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Telefono</label>
                <div class="col-sm-9">
                  <input
                    v-model="patient.phone"
                    type="tel"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                  <input
                    v-model="patient.email"
                    type="email"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Genero</label>
                <div class="col-sm-9">
                  <select
                    v-model="patient.gender"
                    required
                    class="form-control"
                  >
                    <option>Mujer</option>
                    <option>Hombre</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Fecha de Nacimiento</label
                >
                <div class="col-sm-9">
                  <input
                    type="date"
                    v-model="patient.birthDay"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Estado Civil</label>
                <div class="col-sm-9">
                  <select v-model="patient.status" class="form-control">
                    <option>Soltero</option>
                    <option>Casado</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Ocupacion</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    v-model="patient.ocupation"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label bold"
                  >Detalles Adicionales</label
                >
                <div class="col-sm-12">
                  <textarea
                    class="form-control"
                    v-model="patient.additionalInformation"
                    rows="5"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-right">
          <a @click="clearPatient()" class="btn btn-secondary mr-2">
            Descartar
          </a>
          <button type="submit" class="btn btn-primary mr-2">
            <span v-show="patient.id !== undefined && patient.id > 0">
              Actualizar
            </span>
            <span v-show="patient.id === undefined || patient.id === 0">
              Guardar
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<style scoped>
select.form-control {
  padding: 0.875rem 1.375rem;
}
</style>
<script>
import axios from "axios";
import AlertMessage from "../shared/common/alert-message.vue";
export default {
  name: "AddPatientForm",
  props: ["selectedPatient"],
  components: { AlertMessage },
  watch: {
    selectedPatient: function (val) {
      this.patient = val;
    },
  },
  data() {
    return {
      patient: {},
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();

      if (this.patient.id !== undefined && this.patient.id > 0) {
        axios.put("/patients/" + this.patient.id, this.patient).then(
          (result) => {
            if (result.data) {
              this.$emit("patientUpdated", this.patient);
              this.$refs.alertMessage.showAlert(
                "El paciente fue actualizado correctamente",
                true
              );
            }
          },
          () => {
            this.showErrorMessage();
          }
        );
      } else {
        axios.post("/patients", this.patient).then(
          (result) => {
            if (result.data) {
              this.$emit("patientAdded", this.patient);

              this.$refs.alertMessage.showAlert(
                "El paciente fue guardado correctamente",
                true
              );
            } else {
              this.showErrorMessage();
            }
          },
          () => {
            this.showErrorMessage();
          }
        );
      }
    },
    clearPatient() {
      this.patient = {};
    },
    showErrorMessage() {
      this.$refs.alertMessage.showAlert(
        "Ocurrio un error, porfavor intenta de nuevo",
        false
      );
    },
  },
};
</script>
