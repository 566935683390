<template>
  <DefaultLayout>
    <div class="row">
      <div class="col-sm-12 mb-4 mb-xl-0">
        <h4 class="font-weight-bold text-dark">Dietas</h4>
        <p class="font-weight-normal mb-2 text-muted">{{ today }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 grid-margin">
        <SearchGridComponent
          modal-id="dietModal"
          :add-route="'new'"
          endpoint="diet"
          :column-names="[
            'Nombre',
            'Kcal',
            'Carbs',
            'Protein',
            'Grasa',
            'Categoria',
            'Creada',
          ]"
          :properties="[
            'name',
            'kcal',
            'carbs',
            'protein',
            'fat',
            'category',
            'created',
          ]"
          :columnClasses="[
            'bold',
            'badge badge-warning',
            'badge badge-info',
            'badge badge-success',
            'badge badge-danger',
            'badge badge-secondary',
          ]"
          @itemSelected="itemSelectedCallback"
        ></SearchGridComponent>
      </div>
      <dietModal modal-id="dietModal" :selectedDiet="selectedDiet"></dietModal>
    </div>
  </DefaultLayout>
</template>

<script>
import SearchGridComponent from "@/components/shared/grids/search-grid.vue";
import dietModal from "@/components/diets/diet-modal.vue";
import DefaultLayout from "@/views/layouts/default-layout.vue";

export default {
  name: "DietsListView",
  components: {
    SearchGridComponent,
    dietModal,
    DefaultLayout,
  },
  data() {
    return {
      today: new Date().toDateString(),
      selectedDiet: {},
    };
  },
  methods: {
    itemSelectedCallback(item) {
      this.selectedDiet = item;
    },
  },
};
</script>
