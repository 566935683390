<template>
  <div class="input-group search-bar-component">
    <div class="input-group-append">
      <span class="input-group-text h4 mb-3">
        <i class="fa-solid fa-magnifying-glass"></i>
      </span>
    </div>
    <input
      @keyup="onKeyUp"
      @keydown="keydown"
      type="text"
      class="form-control mb-3"
      placeholder="Buscar.."
      aria-label="search"
      aria-describedby="search"
      v-model="searchTerm"
    />
  </div>
</template>

<style scoped>
.search-bar-component input {
  border: none;
}

.search-bar-component .input-group-text {
  background: none;
  border: none;
}
</style>

<script>
let typingTimer;
export default {
  name: "SearchBar",
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    onKeyUp() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (this.searchTerm.length < 3) return;
        this.showLoader = true;
        this.$emit("searchText", this.searchTerm);
      }, 500);
    },
    onKeyDown() {
      clearTimeout(typingTimer);
    },
    reset() {
      this.searchTerm = "";
    },
  },
};
</script>
