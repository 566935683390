<template>
  <LineChart
    v-if="combinedChartdata.datasets.length > 0"
    :chartData="combinedChartdata"
  />
</template>

<script>
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export default {
  name: "PatientCharts",
  components: {
    LineChart,
  },
  props: ["patientData"],
  watch: {
    patientData: {
      handler: function (val) {
        this.combinedChartdata.labels = [];

        this.combinedChartdata.datasets = [];

        this.combinedChartdata.labels = val.patientControlHistory.map((item) =>
          new Date(item.date).toLocaleDateString()
        );

        this.combinedChartdata.datasets.push({
          label: "Peso (Kg.)",
          data: val.patientControlHistory.map((item) => item.weight),
          borderColor: "#ff6361",
          backgroundColor: "#ff6361",
          fill: false,
          tension: 0.4,
        });

        this.combinedChartdata.datasets.push({
          label: "Grasa (Kg.)",
          data: val.patientControlHistory.map((item) => item.kgFat),
          borderColor: "#ffa600",
          backgroundColor: "#ffa600",
          fill: false,
          tension: 0.4,
        });

        this.combinedChartdata.datasets.push({
          label: "Músculo (Kg.)",
          data: val.patientControlHistory.map((item) => item.kgMuscle),
          borderColor: "#bc5090",
          backgroundColor: "#bc5090",
          fill: false,
          tension: 0.4,
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      combinedChartdata: {
        labels: [],
        datasets: [],
      },
    };
  },
};
</script>
