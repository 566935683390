<template>
  <div :class="isDarkTheme ? 'dark-theme' : ''" class="container-scroller">
    <div class="container-fluid page-body-wrapper">
      <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
        <div class="row flex-grow">
          <div
            class="col-lg-6 d-flex align-items-center justify-content-center"
          >
            <login-form @onError="onErrorCallback"></login-form>
          </div>
          <div class="col-lg-6 login-half-bg d-flex flex-row">
            <img src="/static/img/login-bg.jpg" />
            <p
              class="text-white font-weight-medium text-center flex-grow align-self-end"
            >
              Copyright © 2018 All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.login-half-bg {
  background: none;
  background-position: center;
  background-size: cover;
}

.login-half-bg img {
  width: 100%;
  height: 100%;
}
</style>
<script>
import LoginForm from "@/components/users/login-form.vue";

export default {
  name: "LoginLayout",
  components: { LoginForm },
  data() {
    return {
      isDarkTheme: localStorage.getItem("isDarkTheme") === "true" ?? false,
    };
  },
};
</script>
