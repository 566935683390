<template>
  <div class="row">
    <AlertMessage ref="alertMessage"></AlertMessage>
    <div class="col-lg-12 grid-margin stretch-card diets-list">
      <div class="card">
        <div class="card-body">
          <h2 class="display-4">{{ title }}</h2>
          <div class="row mb-10">
            <div class="col-xl-5 col-sm-12">
              <SearchBar
                ref="searchBar"
                @search-text="searchCallback"
              ></SearchBar>
            </div>
            <div class="col-xl-7 col-sm-12">
              <div class="float-right">
                <slot />
                <RouterLink
                  v-if="addRoute !== undefined"
                  :to="addRoute"
                  class="btn btn-primary mr-4"
                >
                  <i class="fa-solid fa-plus"></i>
                </RouterLink>
                <button
                  v-else
                  type="button"
                  data-toggle="modal"
                  :data-target="'#' + modalId"
                  @click="addItem()"
                  class="btn btn-primary mr-4"
                >
                  <i class="fa-solid fa-plus"></i>
                </button>
                <button
                  ref="editButton"
                  v-if="
                    selectedItem.id !== undefined &&
                    selectedItem.id !== null &&
                    selectedItem.id > 0
                  "
                  data-toggle="modal"
                  :data-target="'#' + modalId"
                  type="button"
                  class="btn btn-light mr-4"
                >
                  <i class="fa-solid fa-pen text-primary"></i>
                </button>
                <button
                  v-if="
                    selectedItem.id !== undefined &&
                    selectedItem.id !== null &&
                    selectedItem.id > 0
                  "
                  @click="deleteItem"
                  type="button"
                  class="btn btn-light mr-4"
                >
                  <i class="fa-solid fa-remove text-danger"></i>
                </button>
                <button
                  @click="refreshGrid"
                  type="button"
                  class="btn btn-light mr-4"
                >
                  <i
                    :class="refreshButtonClass"
                    class="fa-solid fa-rotate text-success"
                  ></i>
                </button>
              </div>
            </div>
          </div>
          <dynamicGrid
            :data-source="searchResults.results"
            :column-names="columnNames"
            :properties-displayed="properties"
            :editRoute="editRoute"
            :column-classes="columnClasses"
            @itemSelected="itemSelectedCallback"
            @itemDoubleClicked="doubleClickItemCallback"
            :triggerUnselect="triggerUnselect"
          ></dynamicGrid>
          <br />
          <div class="text-center">
            <Pager
              @click-page="clickPageCallback"
              v-bind:pagerResult="searchResults.pager"
            ></Pager>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dynamicGrid from "@/components/shared/grids/dynamic-grid.vue";
import Pager from "@/components/shared/grids/pager.vue";
import SearchBar from "@/components/shared/search/search-bar.vue";
import AlertMessage from "../common/alert-message.vue";
import { RouterLink } from "vue-router";

const blankSearchTerm = "blank-search-term";

export default {
  name: "SearchGridComponent",
  props: [
    "modalId",
    "endpoint",
    "columnNames",
    "columnClasses",
    "formats",
    "properties",
    "title",
    "dataSource",
    "editRoute",
    "addRoute",
    "pageSize",
    "triggerRefresh",
    "triggerUnselect",
  ],
  watch: {
    triggerRefresh: function () {
      this.refreshGrid();
    },
  },
  components: { Pager, SearchBar, dynamicGrid, RouterLink, AlertMessage },
  data() {
    return {
      searchResults: {},
      selectedItem: {},
      pageIndex: 0,
      searchTerm: blankSearchTerm,
      refreshButtonClass: "",
      listButtonClass: "",
      gridButtonClass: "",
    };
  },
  methods: {
    getData() {
      if (this.dataSource === undefined) {
        this.refreshButtonClass = "fa-spin";
        axios
          .get(
            "/" +
              this.endpoint +
              "/search?term=" +
              this.searchTerm +
              "&pageIndex=" +
              this.pageIndex +
              "&pageSize=" +
              (this.pageSize === undefined ? "10" : this.pageSize.toString())
          )
          .then(
            (response) => {
              this.searchResults = response.data;
              this.refreshButtonClass = "";
            },
            () => {
              this.showErrorMessage();
            }
          );
      } else {
        this.searchResults = this.dataSource;
      }
    },
    clickPageCallback(index) {
      this.pageIndex = index;
      this.getData();
    },
    searchCallback(term) {
      this.searchTerm = term;
      this.pageIndex = 0;
      this.getData();
    },
    refreshGrid() {
      this.searchTerm = blankSearchTerm;
      this.pageIndex = 0;
      this.$refs.searchBar.reset();
      this.getData();
      this.addItem();
    },
    switchToCardGrid(isCardMode) {
      this.gridMode = isCardMode;
    },
    deleteItem() {
      axios.delete("/" + this.endpoint + "/" + this.selectedItem.id).then(
        (response) => {
          if (response.data) {
            this.getData();
            this.$refs.alertMessage.showAlert("Operacion Exitosa!", true);
          }
        },
        () => {
          this.showErrorMessage();
        }
      );
    },
    addItem() {
      this.selectedItem = { isEmpty: true };
      this.$emit("itemSelected", this.selectedItem);
    },
    itemSelectedCallback(item) {
      this.selectedItem = item;
      this.$emit("itemSelected", item);
    },
    doubleClickItemCallback(item) {
      this.selectedItem = item;
      this.$emit("itemSelected", item);
      //delayed for 300ms to allow the modal to be rendered
      setTimeout(() => {
        this.$refs.editButton.click();
      }, 300);
    },
    showErrorMessage() {
      this.$refs.alertMessage.showAlert(
        "Ocurrio un error, porfavor intenta de nuevo",
        false
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>
