<template>
  <div class="add-diet-component">
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="form-designer">
          <AlertMessage ref="alertMessage"></AlertMessage>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-inline mb-3">
                <form @submit="save">
                  <input type="hidden" v-model="diet.id" />
                  <input
                    type="text"
                    class="form-control mr-sm-2 mb-2"
                    v-model="diet.name"
                    required
                    placeholder="Nombre"
                    :readonly="designerMode === 'Preview'"
                  />
                  <input
                    type="text"
                    class="form-control mr-sm-2 mb-2"
                    v-model="diet.category"
                    required
                    placeholder="Categoria"
                    :readonly="designerMode === 'Preview'"
                  />
                  <router-link
                    v-if="designerMode === 'Preview'"
                    :to="'/diets/edit/' + diet.id"
                    data-dismiss="modal"
                    class="btn mb-2 mr-3 btn-primary"
                    ><i class="fa-solid fa-pen menu-icon"></i>
                  </router-link>
                  <button
                    v-if="designerMode === 'Edit'"
                    :disabled="isFormProcessing"
                    type="submit"
                    class="btn mr-3 mb-2 btn-primary"
                  >
                    <i
                      :class="
                        isFormProcessing
                          ? 'fa-solid fa-circle-notch fa-spin'
                          : 'fa-solid fa-floppy-disk'
                      "
                    ></i>
                  </button>
                  <a
                    v-if="designerMode === 'Edit' && diet.id !== undefined"
                    :disabled="isFormProcessing"
                    class="btn mr-3 mb-2 btn-primary"
                    @click="saveAs"
                  >
                    <i
                      :class="
                        isFormProcessing
                          ? 'fa-solid fa-circle-notch fa-spin'
                          : 'fa-solid fa-copy'
                      "
                    ></i>
                  </a>
                  <a
                    class="btn mr-3 mb-2 btn-light"
                    data-toggle="modal"
                    data-target="#patient-list-modal"
                  >
                    <i class="fa-regular fa-calendar-check"></i>
                  </a>
                  <a
                    @click="printDiet($event)"
                    type="submit"
                    class="btn btn-secondary mb-2"
                  >
                    <i class="fa-solid fa-print"></i>
                  </a>
                </form>
              </div>
            </div>
            <div class="col-sm-6 text-right">
              <h6 class="mt-0">Informacion Nutricional</h6>
              <span class="h6 ml-3 mb-2">
                <strong>
                  <i class="fa-solid fa-fire"></i> Kcal:
                  <span class="h6 text-dark badge badge-warning">
                    {{ diet.kcal }}
                  </span>
                </strong>
              </span>
              <span class="h6 ml-3 mb-2">
                <strong>
                  <i class="fa-solid fa-egg"></i> Proteina:
                  <span class="h6 badge badge-info">
                    {{ diet.protein }}
                  </span>
                </strong>
              </span>
              <span class="h6 ml-3 mb-2">
                <strong>
                  <i class="fa-solid fa-pizza-slice"></i> Carbs:
                  <span class="h6 text-dark badge badge-secondary">
                    {{ diet.carbs }}
                  </span>
                </strong>
              </span>
              <span class="h6 ml-3 mb-2">
                <strong>
                  <i class="fa-solid fa-bacon"></i> Grasa:
                  <span class="h6 badge badge-danger">
                    {{ diet.fat }}
                  </span>
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-xl-2 col-md-3 col-sm-12 week-column flex-column d-flex grid-margin stretch-card"
        v-for="weekDay in diet.days"
        :key="weekDay.id"
      >
        <div class="text-center week-info badge mb-3">
          <div class="row">
            <div class="week-day h5 badge badge-success text-dark mt-3 col-12">
              {{ weekDay.dayName }}
            </div>
            <div class="mt-3 week-meal-information text-center col-12">
              <span class="h6 badge badge-warning">
                <i class="fa-solid fa-fire"></i>&nbsp;
                <strong class="">{{ weekDay.kcal }}</strong> </span
              >&nbsp;
              <span class="h6 badge badge-info">
                <i class="fa-solid fa-egg"></i>&nbsp;
                <strong class="">{{ weekDay.protein }}</strong> </span
              ><br />
              <span class="h6 badge badge-danger">
                <i class="fa-solid fa-bacon"></i>&nbsp;
                <strong class="">{{ weekDay.fat }}</strong> </span
              >&nbsp;
              <span class="h6 badge badge-secondary">
                <i class="fa-solid fa-pizza-slice"></i>&nbsp;
                <strong class="">{{ weekDay.carbs }} </strong>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-sm-12 grid-margin diet-meal"
            v-for="(meal, index) in weekDay.meals"
            :key="meal.id"
          >
            <div class="card">
              <div class="card-body">
                <div
                  v-if="designerMode == 'Edit'"
                  class="btn-group hide"
                  role="group"
                >
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#diet-meal-modal-component"
                    class="btn btn-sm btn-light btn-edit-meal"
                    @click="editMeal(meal, weekDay)"
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-light btn-remove-meal"
                    @click="removeMeal(weekDay, index)"
                  >
                    <i class="fa-solid fa-trash-can"></i>
                  </button>
                </div>
                <h5>
                  <strong class="badge text-dark badge-success">{{
                    meal.type
                  }}</strong
                  ><strong class="badge badge-warning float-right"
                    >Kcal {{ meal.kcal }}</strong
                  >
                </h5>
                <h6>
                  <strong>{{ meal.name }}</strong>
                </h6>
                <ul class="ingredients-list">
                  <li v-for="food in meal.food" :key="food.id">
                    <strong class="badge badge-primary">{{
                      food.quantity
                    }}</strong>
                    {{ food.unit }} <strong>{{ food.name }}</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="designerMode === 'Edit'"
            class="col-sm-12 grid-margin add-diet-meal"
            data-toggle="modal"
            @click="addNewMeal(weekDay)"
            data-target="#diet-meal-modal-component"
          >
            <div class="card">
              <div class="card-body">
                <i class="icon-marquee-plus text-success"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MealModal
    v-if="designerMode === 'Edit'"
    :modalId="'diet-meal-modal-component'"
    :formMode="'dietEdit'"
    @mealAdded="addNewMealCallback"
    @mealUpdated="addNewMealCallback"
    :selectedMeal="selectedMeal"
    :selectedWeekDay="selectedWeekDay"
  />
  <PatientListModal
    v-if="designerMode === 'Edit'"
    :modalId="'patient-list-modal'"
    :diet="diet"
  />
</template>

<style scoped>
.week-column {
  max-width: 14.28%;
}
.food-item {
  font-size: 17px;
  margin: 10px 0;
}

.form-designer .card-body {
  padding: 10px;
}

.form-designer .card-body .info-pane .badge {
  margin-bottom: 0;
}

.add-diet-component .diet-meal .card-body {
  padding: 10px 5px;
  cursor: pointer;
}

.add-diet-component .add-diet-meal .card-body {
  min-height: auto;
}

.add-diet-component .diet-meal .card-body .btn-group {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.add-diet-component .diet-meal .card-body h6 {
  margin: 15px 0 10px 0;
  text-transform: capitalize;
}

.add-diet-component .diet-meal .card-body .ingredients-list {
  background: #f4f7fa;
  border-radius: 5px;
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dark-theme .add-diet-component .diet-meal .card-body .ingredients-list {
  background: #080a0e;
}

.add-diet-component .diet-meal .card-body:hover .btn-group {
  display: block;
}

.add-diet-component .add-diet-meal .card {
  border: 2px dashed #e5e8ea;
  cursor: pointer;
}

.add-diet-component .add-diet-meal .card:hover {
  background: #f4f7fa;
}

.add-diet-component .add-diet-meal .card .card-body {
  font-size: 25pt;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .week-column {
    max-width: 100%;
  }
  .form-inline .h4,
  .form-inline input {
    display: block;
    width: 100% !important;
  }

  .form-inline form {
    width: 100%;
  }
}

@media print {
  .col-sm-12.mb-4.mb-xl-0,
  .form-designer,
  .add-diet-meal {
    display: none;
  }

  .badge {
    border: none;
    color: #000;
  }

  .week-info {
    border: none;
  }

  .week-info .badge,
  .week-info .week-meal-information {
    display: none !important;
  }

  .week-info .week-day {
    display: block !important;
    background: none;
  }

  .week-column {
    float: left;
    width: 14.28%;
  }

  .diet-meal {
    margin: 0;
    padding: 0;
  }

  .card {
    border: none;
  }

  .card-body {
    padding: 0;
    height: auto !important;
    min-height: auto !important;
  }

  .badge-success {
    display: block;
    background: none;
    color: #000;
    border: none;
  }

  h6 {
    text-align: center;
    font-size: 12pt;
  }

  h4,
  p,
  li,
  ul,
  strong {
    font-size: 8pt !important;
    padding: 0;
    color: #000;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  ul li {
    border-bottom: 1px dashed #000;
  }
}
</style>

<script>
import axios from "axios";
import MealModal from "@/components/meals/meal-modal.vue";
import PatientListModal from "../patients/patient-list-modal.vue";
import AlertMessage from "@/components/shared/common/alert-message.vue";
import router from "@/router";

export default {
  name: "DietDesigner",
  components: { MealModal, AlertMessage, PatientListModal },
  props: ["selectedDiet", "designerMode"],
  watch: {
    selectedDiet: function (newVal) {
      if (newVal.isEmpty === true) {
        this.diet = this.getEmptyDiet();
      } else {
        this.diet = newVal;
      }
    },
  },
  data() {
    return {
      today: new Date().toDateString(),
      diet: this.getEmptyDiet(),
      selectedWeekDay: {},
      selectedMeal: {},
      isFormProcessing: false,
    };
  },
  methods: {
    getEmptyDiet() {
      var emptyDiet = {
        kcal: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
        name: "",
        category: "",
        days: [
          this.getEmptyDay("Lunes"),
          this.getEmptyDay("Martes"),
          this.getEmptyDay("Miercoles"),
          this.getEmptyDay("Jueves"),
          this.getEmptyDay("Viernes"),
          this.getEmptyDay("Sabado"),
          this.getEmptyDay("Domingo"),
        ],
      };

      return emptyDiet;
    },
    getEmptyDay(dayName) {
      return {
        dayName: dayName,
        meals: [],
        kcal: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
      };
    },
    getEmptyMeal() {
      return {
        type: "",
        name: "",
        food: [],
        kcal: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
        actionMode: "add",
      };
    },
    printDiet(e) {
      e.preventDefault();

      window.print();
    },
    addNewMeal(weekDay) {
      this.selectedWeekDay = weekDay;
      this.selectedMeal = this.getEmptyMeal();
    },
    addNewMealCallback(meal) {
      console.log(meal);
      if (this.selectedMeal.id === undefined || this.selectedMeal.id === 0) {
        this.selectedWeekDay.meals.push(meal);
      }

      this.calculateDay();

      this.selectedMeal = this.getEmptyMeal();
    },
    editMeal(meal, weekDay) {
      this.selectedMeal = meal;
      this.selectedMeal.actionMode = "edit";
      this.selectedWeekDay = weekDay;
    },
    removeMeal(weekDay, index) {
      this.selectedWeekDay = weekDay;
      weekDay.meals.splice(index, 1);
      this.calculateDay();
    },
    saveAs() {
      this.diet.id = 0;
      this.save(undefined, true);
    },
    save(e, isSaveAs) {
      if (e !== undefined) {
        e.preventDefault();
      }

      let dietId = undefined;

      if (!isSaveAs) {
        this.$route.params["id"];

        if (dietId === undefined) {
          dietId = this.diet.id;
        }
      }

      this.isFormProcessing = true;

      if (dietId === undefined) {
        axios.post("/diet/", this.diet).then(
          (result) => {
            if (result.data.success) {
              this.diet = result.data.entity;
              this.$refs.alertMessage.showAlert("Operacion Exitosa!", true);
              if (isSaveAs) {
                router.push("/diets/edit/" + this.diet.id);
              }
            } else {
              this.showErrorMessage();
            }
            this.isFormProcessing = false;
          },
          () => {
            this.isFormProcessing = false;
            this.showErrorMessage();
          }
        );
      } else {
        axios.put("/diet/" + dietId, this.diet).then(
          (result) => {
            if (result.data) {
              this.$refs.alertMessage.showAlert("Operacion Exitosa!", true);
            } else {
              this.showErrorMessage();
            }
            this.isFormProcessing = false;
          },
          () => {
            this.isFormProcessing = false;
            this.showErrorMessage();
          }
        );
      }
    },
    calculateDay() {
      this.selectedWeekDay.kcal = 0;
      this.selectedWeekDay.meals.forEach((meal) => {
        this.selectedWeekDay.kcal += meal.kcal;
        this.selectedWeekDay.protein += meal.protein;
        this.selectedWeekDay.carbs += meal.carbs;
        this.selectedWeekDay.fat += meal.fat;
      });

      this.calculateDiet();
    },
    calculateDiet() {
      this.diet.kcal = 0;
      this.diet.days.forEach((day) => {
        this.diet.kcal += day.kcal;
        this.diet.protein += day.protein;
        this.diet.carbs += day.carbs;
        this.diet.fat += day.fat;
      });
    },
    showErrorMessage() {
      this.$refs.alertMessage.showAlert(
        "Ocurrio un error, porfavor intenta de nuevo",
        false
      );
    },
  },
  mounted() {
    if (this.$route.params["id"] !== undefined) {
      axios.get("/diet/" + this.$route.params["id"]).then((result) => {
        this.diet = result.data;
      });
    }
  },
};
</script>
