<template>
  <DefaultLayout>
    <div class="row">
      <div class="col-sm-12 mb-4 mb-xl-0 page-title">
        <h4 class="font-weight-bold text-dark">Dietas</h4>
        <p class="font-weight-normal mb-2 text-muted">{{ today }}</p>
      </div>
    </div>
    <DietDesigner :designer-mode="'Edit'" />
  </DefaultLayout>
</template>
<style scoped>
@media print {
  .page-title {
    display: none;
  }
}
</style>
<script>
import DefaultLayout from "@/views/layouts/default-layout.vue";
import DietDesigner from "@/components/diets/diet-designer.vue";

export default {
  name: "DietsDesignerView",
  components: {
    DefaultLayout,
    DietDesigner,
  },
  data() {
    return {
      today: new Date().toDateString(),
      selectedItem: {},
    };
  },
};
</script>
