<template>
  <GenericModal ref="modal" :modalId="modalId">
    <PatientForm
      @patientAdded="addPatientCallback"
      @patientUpdated="addPatientCallback"
      :selected-patient="selectedPatient"
    ></PatientForm>
  </GenericModal>
</template>
<script>
import PatientForm from "@/components/patients/patient-form.vue";
import GenericModal from "../shared/common/generic-modal.vue";

export default {
  name: "PatientModal",
  components: { PatientForm, GenericModal },
  props: ["selectedPatient", "formMode", "modalId"],
  methods: {
    addPatientCallback(patient) {
      this.$emit("patientAdded", patient);
      this.$refs.modal.close();
    },
    deletePatientCallback(patient) {
      this.$emit("patientDeleted", patient);
      this.$refs.modal.close();
    },
  },
};
</script>
