<template>
  <DefaultLayout>
    <div class="row">
      <div class="col-sm-12 mb-4 mb-xl-0">
        <h4 class="font-weight-bold text-dark">
          Historial de {{ patientData.patient.firstName }}
          {{ patientData.patient.lastName }}!
        </h4>
        <p class="font-weight-normal mb-2 text-muted">{{ today }}</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-xl-5 flex-column d-flex grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"><i class="icon-head"></i>&nbsp;Paciente</h4>
            <p>
              <strong>Fecha de nacimiento:</strong>
              {{ formartDate(patientData.patient.birthDay) }}
            </p>
            <p><strong>Genero:</strong> {{ patientData.patient.gender }}</p>
            <p>
              <strong>Ocupacion:</strong> {{ patientData.patient.ocupation }}
            </p>
            <p><strong>Telefono:</strong> {{ patientData.patient.phone }}</p>
            <p><strong>Email:</strong> {{ patientData.patient.email }}</p>
            <button
              title="Registrar Historial de control"
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#patient-tracker-modal-component"
            >
              <i class="icon-paper-stack"></i>
            </button>
            <button
              title="Asignar Dieta"
              type="button"
              class="btn btn-secondary ml-2"
            >
              <i class="icon-layout"></i>
            </button>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <h4 class="card-title">
              <i class="fa-solid fa-calendar"></i>&nbsp;Historial de control
            </h4>
            <br />
            <dynamicGrid
              :disableSelection="true"
              :data-source="patientData.patientControlHistory"
              :column-classes="[
                '',
                'badge badge-weight',
                'badge badge-fat',
                'badge badge-muscle',
              ]"
              :formats="['date', '', '', '']"
              :column-names="['Fecha', 'Peso', 'Grasa', 'Musculo']"
              :properties-displayed="[
                'date',
                'weight',
                'fatPercentage',
                'musclePercentage',
              ]"
            ></dynamicGrid>
          </div>
        </div>
      </div>
      <div class="col-xl-7 d-flex grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              Medidas Primarias <i class="fa-solid fa-chart-simple"></i>
            </h4>
            <p>
              Comparativo de medias corporales tales como Peso (kg), Grasa (%) y
              Musculo (%)
            </p>
            <br />
            <PatientCharts
              v-if="patientData !== undefined && patientData !== null"
              :patient-data="patientData"
            />
            <h5 class="text-center no-data-label" v-else>
              No hay datos para mostrar
            </h5>
          </div>
        </div>
      </div>
      <PatientTrackerModal
        :modalId="'patient-tracker-modal-component'"
        :selected-patient="patientData"
      ></PatientTrackerModal>
    </div>
  </DefaultLayout>
</template>

<style>
.badge-weight {
  background-color: #ff6361;
}
.badge-fat {
  background-color: #ffa600;
}
.badge-muscle {
  background-color: #bc5090;
  color: #fff !important;
}
</style>

<script>
import axios from "axios";
import DefaultLayout from "../layouts/default-layout.vue";
import DynamicGrid from "@/components/shared/grids/dynamic-grid.vue";
import PatientTrackerModal from "@/components/patients/patient-tracker-modal.vue";
import PatientCharts from "@/components/patients/patient-charts.vue";

export default {
  components: {
    DefaultLayout,
    DynamicGrid,
    PatientTrackerModal,
    PatientCharts,
  },
  data() {
    return {
      today: new Date().toDateString(),
      patientData: { patient: {}, patientControlHistory: [] },
    };
  },
  mounted() {
    let patientId = this.$route.params["id"];

    if (patientId !== undefined) {
      axios.get("/patients/history?id=" + patientId).then((result) => {
        console.log(result.data);
        this.patientData = result.data;
      });
    }
  },
  methods: {
    formartDate(dateString) {
      if (dateString === undefined || dateString === null) {
        return "";
      }
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("default", { dateStyle: "short" }).format(
        date
      );
    },
  },
};
</script>
<style scoped>
.no-data-label {
  line-height: 250px;
}
</style>
