<template>
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div
      class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
    >
      <a class="navbar-brand brand-logo" href="/">
        <span class="badge badge-primary"
          ><i class="fa-solid fa-wheat-awn"></i></span
        >&nbsp;Nutrition Suite
      </a>
      <a class="navbar-brand brand-logo-mini" href="">
        <span class="badge badge-primary"
          ><i class="fa-solid fa-wheat-awn"></i></span
      ></a>
    </div>
    <div
      class="navbar-menu-wrapper d-flex align-items-center justify-content-end"
    >
      <button
        @click="toggleSidebar"
        class="navbar-toggler navbar-toggler align-self-center"
        type="button"
        data-toggle="minimize"
      >
        <span class="icon-menu"></span>
      </button>
      <ul class="navbar-nav mr-lg-2">
        <li class="nav-item nav-search d-none d-lg-block"></li>
      </ul>
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item dropdown d-flex mr-4">
          <a
            class="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"
            id="notificationDropdown"
            href="#"
            data-toggle="dropdown"
          >
            <i class="icon-cog"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
            aria-labelledby="notificationDropdown"
          >
            <p class="mb-0 font-weight-normal float-left dropdown-header">
              Settings
            </p>
            <a @click="logout()" class="dropdown-item preview-item">
              <i class="icon-inbox"></i> Logout
            </a>
          </div>
        </li>
        <li class="nav-item dropdown mr-4 d-lg-flex d-none">
          <a
            class="nav-link count-indicatord-flex align-item s-center justify-content-center"
            href="#"
          >
            <i class="icon-grid"></i>
          </a>
        </li>
        <li @click="toggleTheme($event)" class="nav-item dropdown mr-4 d-flex">
          <a
            class="nav-link count-indicatord-flex align-item s-center justify-content-center"
            href="#"
          >
            <i :class="isDarkTheme ? 'icon-sun' : 'icon-moon'"></i>
          </a>
        </li>
      </ul>
      <button
        @click="toggleSidebarMobile()"
        class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
      >
        <span class="icon-menu"></span>
      </button>
    </div>
  </nav>
</template>
<style scoped>
.brand-logo {
  font-size: 1.5rem;
}
.brand-logo-mini {
  font-size: 1.5rem;
  color: #fff !important;
}
@media print {
  .navbar .navbar-menu-wrapper,
  .navbar-brand-wrapper,
  .nav-item {
    display: none;
  }
}
</style>
<script>
export default {
  name: "NavBar",
  data() {
    return {
      isDarkTheme: localStorage.getItem("isDarkTheme") === "true" ?? false,
    };
  },
  methods: {
    printPage(e) {
      e.preventDefault();

      window.print();
    },
    toggleTheme(e) {
      e.preventDefault();
      this.isDarkTheme = !this.isDarkTheme;
      localStorage.setItem(
        "isDarkTheme",
        (this.isDarkTheme === true).toString()
      );
      this.$emit("toggleTheme", this.isDarkTheme);
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
    toggleSidebar() {
      document.body.classList.toggle("sidebar-icon-only");
    },
    toggleSidebarMobile() {
      this.$emit("toggleSidebarMobile");
    },
  },
};
</script>
