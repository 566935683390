<template>
  <div
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div :class="customClass" class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body content-wrapper">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            ref="close-modal-btn"
            type="button"
            class="btn btn-light"
            data-dismiss="modal"
          >
            Cerrar &nbsp;<i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.modal-header {
  background: #5e3aa7;
  color: #fff;
}
</style>
<script>
export default {
  name: "GenericModal",
  props: ["modalId", "title", "customClass"],
  methods: {
    close() {
      setTimeout(() => {
        this.$refs["close-modal-btn"].click();
      }, 300);
    },
  },
};
</script>
