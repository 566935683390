import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

//axios.defaults.baseURL = "https://localhost:7265/api/";
//axios.defaults.baseURL = "http://nutritionservices.com/api/";
axios.defaults.baseURL =
  "https://nutritionsuiteservices.azurewebsites.net/api/";

axios.interceptors.request.use((config) => {
  config.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");
  return config;
});

axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (err) => {
    if (
      err.response.status === 401 &&
      router.currentRoute.value.path !== "/login"
    ) {
      router.push("/login");
    }
    return Promise.reject(err);
  }
);

createApp(App).use(router).mount("#app");
