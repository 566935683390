<template>
  <GenericModal ref="modal" :modal-id="modalId">
    <IngredientForm
      @ingredientCreated="createdCallBack"
      @ingredientUpdated="updatedCallBack"
      :selected-ingredient="selectedIngredient"
    ></IngredientForm>
  </GenericModal>
</template>
<style scoped>
.modal-header {
  background: #5e3aa7;
  color: #fff;
}
</style>
<script>
import IngredientForm from "@/components/ingredients/ingredient-form.vue";
import GenericModal from "../shared/common/generic-modal.vue";

export default {
  name: "IngredientModal",
  components: { IngredientForm, GenericModal },
  props: ["selectedIngredient", "modalId"],
  methods: {
    createdCallBack(data) {
      this.$emit("ingredientCreated", data);
      this.$refs.modal.close();
    },
    updatedCallBack(data) {
      this.$emit("ingredientUpdated", data);
      this.$refs.modal.close();
    },
  },
};
</script>
