<template>
  <DefaultLayout>
    <div class="row">
      <div class="col-sm-12 mb-4 mb-xl-0">
        <h4 class="font-weight-bold text-dark">Pacientes</h4>
        <p class="font-weight-normal mb-2 text-muted">{{ today }}</p>
      </div>
    </div>
    <SearchGridComponent
      :modalId="'patient-modal-component'"
      endpoint="patients"
      :column-names="['Nombre', 'Apellido', 'Telefono', 'Email']"
      column-classes="['','','','']"
      :properties="['firstName', 'lastName', 'phone', 'email']"
      @itemSelected="itemSelectedCallback"
      :trigger-unselect="triggerUnselectOnGrid"
      :trigger-refresh="triggerRefreshOnGrid"
    >
      <router-link
        v-show="
          selectedItem.id !== undefined &&
          selectedItem.id !== null &&
          selectedItem.id > 0
        "
        :to="'/patients/dashboard/' + selectedItem.id"
        class="btn btn-secondary mr-4"
      >
        <i class="fa-solid fa-calendar-plus text-success"></i>
      </router-link>
    </SearchGridComponent>
    <PatientModal
      :modalId="'patient-modal-component'"
      @patientAdded="onAddPatientCallback"
      @patientDeleted="onDeletedPatientCallback"
      @patientUpdated="onEditPatientCallback"
      :selected-patient="selectedItem"
      :form-mode="'addEdit'"
    ></PatientModal>
  </DefaultLayout>
</template>

<script>
import SearchGridComponent from "@/components/shared/grids/search-grid.vue";
import DefaultLayout from "@/views/layouts/default-layout.vue";
import PatientModal from "@/components/patients/patient-modal.vue";

export default {
  name: "PatientsView",
  components: {
    SearchGridComponent,
    DefaultLayout,
    PatientModal,
  },
  data() {
    return {
      today: new Date().toDateString(),
      selectedItem: {},
      triggerUnselectOnGrid: false,
      triggerRefreshOnGrid: false,
    };
  },
  methods: {
    itemSelectedCallback(item) {
      this.selectedItem = item;
    },
    onAddPatientCallback() {
      this.selectedItem = {};
      this.triggerRefreshOnGrid = !this.triggerRefreshOnGrid;
    },
    onEditPatientCallback() {
      this.selectedItem = {};
      this.triggerUnselectOnGrid = !this.triggerUnselectOnGrid;
    },
    onDeletedPatientCallback() {
      this.selectedItem = {};
      this.triggerRefreshOnGrid = !this.triggerRefreshOnGrid;
    },
  },
};
</script>
