<template>
  <GenericModal ref="modal" :modalId="modalId">
    <SearchGrid
      endpoint="patients"
      :column-names="['Nombre', 'Apellido', 'Telefono', 'Email']"
      column-classes="['','','','']"
      :properties="['firstName', 'lastName', 'phone', 'email']"
      @itemSelected="itemSelectedCallback"
    >
    </SearchGrid>
    <button
      class="btn btn-primary"
      :disabled="
        selectedPatient == null || selectedPatient.isSelected === false
      "
      @click="assignDiet"
    >
      Asignar Dieta <i class="fas fa-check"></i>
    </button>
  </GenericModal>
</template>
<script>
import GenericModal from "../shared/common/generic-modal.vue";
import SearchGrid from "../shared/grids/search-grid.vue";
import axios from "axios";

export default {
  name: "PatientListModal",
  components: {
    GenericModal,
    SearchGrid,
  },
  props: ["diet", "modalId"],
  data() {
    return {
      selectedPatient: null,
    };
  },
  methods: {
    itemSelectedCallback(patient) {
      this.selectedPatient = patient;
    },
    assignDiet() {
      axios
        .get(
          `/patients/assigndiet?dietId=${this.diet.id}&patientId=${this.selectedPatient.id}`
        )
        .then(
          (response) => {
            this.$emit("dietAssigned", response.data);
            this.$refs.modal.close();
          },
          (error) => {
            this.$emit("dietAssignedError", error);
            this.$refs.modal.close();
          }
        );
    },
  },
};
</script>
